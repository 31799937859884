<template>
  <div>
    All package types:
    <span class="footer-links">
      <LinkList :items="items"></LinkList>
    </span>
    <p class="pt-3 pb-5">Postage prices can change at any time, so we take no responsibility for outdated information.
      Please check the Royal Mail website for the latest information.</p>
  </div>
</template>

<script>
import packages from "@/sites/howmanystamps/data/packages.json";
import LinkList from "@/components/LinkList.vue";
import stringHelpers from "@/scripts/helpers/stringHelpers.js";

export default {
  name: "FooterBody",
  components: { LinkList },
  data: function () {
    return {
      items: []
    };
  },
  created: function () {
    for (var pkg of packages) {
      this.items.push({ key: pkg.key, title: pkg.name, url: `/${pkg.key}` });
    }

    stringHelpers.sortByProperty(this.items, 'title');

    this.items.push({ key: 'articles', title: 'Articles About Stamps', url: '/articles' });
  }
};
</script>

<style lang="scss">

</style>